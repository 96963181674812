<template>
  <section>
    <!-- <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/records/operators"><a>Kartoteki</a></router-link>
          <router-link tag="li" to="/records/operators" class="is-active"><a>Operatorzy</a></router-link>
        </ul>   
      </nav>
    </portal-target> -->
    <div class="ym-toptitle-box">
      <fixed-header :threshold="0" fixedClass="ym-toptitle-fixed">
        <div v-if="selectAOperator && isOnline" id="toptitle" class="level ym-toptitle">
          <div class="level-left">
            <a @click="exit" class="button xbtn is-medium is-transparent"><icon name="x"></icon></a>
            <div>
              <h2 class="title level-left">Wybierz operatora</h2>
            </div>            
          </div> 
        </div>        
      </fixed-header> 
    </div>     
    <div class="columns is-variable is-6 is-multiline">
      <div class="column is-12">
        <div class="ym-searchbox">
          <b-input ref="searchInput" v-model="searchQuery" size="is-medium" placeholder="SZUKAJ..."></b-input>
          <div class="field level level-item level-right ym-searchbox-right" v-if="this.searchQuery">
            również nieaktywni
            <b-checkbox v-model="searchDeleted"
              size="is-medium"
              true-value="1"
              false-value="0"
              @change.native="toggleSearchDeleted"
              >
            </b-checkbox>                  
          </div>
        </div>    
       
        <b-table
          :data="operators"

          :mobile-cards="false"
          paginated
          backend-pagination
          :total="total"
          :per-page="perPage"
          @page-change="onPageChange"

          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort">


          <b-table-column field="lastname" label="Nazwisko i imię" sortable v-slot="props">
            <a @click.prevent="details(props.row)">{{ props.row.lastname + ' ' + props.row.firstname }}</a>
          </b-table-column>
          <b-table-column field="firmname" label="Nazwa firmy" sortable v-slot="props">
            <a @click.prevent="details(props.row)">{{ props.row.firmname }}</a>
          </b-table-column>
          <b-table-column field="email" label="Email" sortable v-slot="props">
            <a @click.prevent="details(props.row)">{{ props.row.email }}</a>
          </b-table-column>
          <b-table-column field="phone" label="Telefon" v-slot="props">
            <a @click.prevent="details(props.row)">{{ props.row.phone }}</a>
          </b-table-column>
          <b-table-column field="nip" label="NIP" v-slot="props">
            <a @click.prevent="details(props.row)">{{ props.row.nip }}</a>
          </b-table-column>

          <b-table-column field="" label="" width="100" v-slot="props">
            <router-link v-if="selectAOperator === true" tag="a" class="button is-small40" :to="{ name: 'OperatorsDetails', params: { id: props.row.id }}">więcej</router-link>            
          </b-table-column>          
          <template slot="empty" v-if="!loading">
            <section class="section">
              <div class="content" style="justify-content: center;font-weight: 600;font-size: 16px; color: #000;" v-if="!loading">
                <p><i class="mdi mdi-magnify mdi-24px" style="position: relative; top: 4px; margin-right: 16px;"></i> Brak wyników wyszukiwania.</p>
              </div>
            </section>
          </template>            
        </b-table>
      </div>
    </div>   
  </section>
</template>

<script>
  import FixedHeader from 'vue-fixed-header'
  import {mapActions, mapGetters} from 'vuex'
  import debounce from '@/utils/debounce';
  import YMmodal from '@/components/Modal.vue';

  export default {
    name: 'Operators',
    components: {
      FixedHeader
    },
    data() {
      return {
        operators: [],
        loading: false,
        total: 0,
        sortField: 'firmname',
        sortOrder: 'asc',
        defaultSortOrder: 'asc',
        page: 1,
        perPage: 50,
        searchQuery: '',
        searchDeleted: 0, 
        selected: null       
      }
    },
    watch: {
      searchQuery() {
        this.debouncedSearchQuery()
      }
    }, 
    mounted() {
      this.$refs.searchInput.$el.children[0].focus()

    }, 
    created() {
      this.debouncedSearchQuery = debounce(this.getUsers, 500)
      this.getUsers()
      document.addEventListener('keyup', this.tableNav)
      Array.from(document.querySelectorAll('table tbody tr')).forEach(node => { node.classList.add('normal') })  
    },  
    destroyed() {
      document.removeEventListener('keyup', this.tableNav);
    },
    computed:{
      ...mapGetters({
        selectAOperator: 'orders/selectAOperator',
        order: 'orders/order'
      }),         
      trows() {
        return document.querySelector('table').rows
      }
    },
    methods: {
      ...mapActions({
        getUsersCall: 'records/getUsers',
        removeUserCall: 'records/removeUser'
      }),
      getUsers() {
        this.loading = true
        const { page, perPage, sortField, sortOrder, searchQuery, searchDeleted } = this

        this.getUsersCall({page, perPage, sortField, sortOrder, searchQuery, searchDeleted, function_role: 'operator'})
          .then(resp => {
            this.total = resp.page.items
            if(this.total <= this.perPage) {
              document.querySelector('.pagination').style.display = "none"
            }   
            this.operators = resp.data
          })
          .catch((error) => {
            this.operators = []
            this.total = 0
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          })
          .finally(() => this.loading = false)
      },
      userRemove(id) {
        this.$buefy.modal.open({
          parent: this,
          component: YMmodal,
          hasModalCard: true,
          props: {
            title: 'Czy na pewno chcesz usunąć użytkownika?',
            content: `<span class='is-danger'>Usunięcia uzytkownika nie można cofnąć.</span> Użytkownik pozostanie w utworzonych do tej pory zleceniach.`,
            cancel: 'Anuluj',
            ymHasIcon: true,
            ymIcon: 'trash',
            submit: `<span class="mal50i">Usuń</span>`,
            ymConfirm: true,
              ymOnConfirm: () => {
                this.removeUserCall({id})
                .then(({ data }) => {
                  document.querySelector('.is-cancel').click()
                  this.getUsers()
                  this.$buefy.toast.open({
                      duration: 5000,
                      message: data.alert[1],
                      type: 'is-success',
                      position: 'is-bottom'
                  })  
                })
                .catch(() => {
                  document.querySelector('.is-cancel').click()
                })                 
              }
          }
        }) 
      },
      onPageChange(page) {
        this.page = page
        if(this.searchQuery === '' || this.searchQuery === null) {
          this.getUsers()
        }
      },
      onSort(field, order) {
        this.sortField = field
        this.sortOrder = order
        this.getUsers()
      },
      tableNav($event) {
        var trows = this.trows, trow, nextrow;

        switch($event.keyCode){
          case 13: {
            return active()
          }
          case 38: {
            return movehighlight(-1, $event)
          }
          case 40: {
            return movehighlight(1, $event);
          }
          default: {
            return true;
          }
        }    
        
        function active() {
          document.querySelector('table tbody tr.is-selected a').click()
        }
        
        function movehighlight(way, e) {
          e.preventDefault && e.preventDefault();
          e.returnValue = false;
          var idx = highlightRow(true); //gets current index or null if none highlighted
          
          if(typeof idx === 'number'){//there was a highlighted row
            idx += way; //increment\decrement the index value
            if(idx && (nextrow = trows[idx])){ return highlightRow.apply(nextrow); } //index is > 0 and a row exists at that index
            else if(idx){ return highlightRow.apply(trows[1]); } //index is out of range high, go to first row
            return highlightRow.apply(trows[trows.length - 1]); //index is out of range low, go to last row
          }
          return highlightRow.apply(trows[way > 0? 1 : trows.length - 1]); //none was highlighted - go to 1st if down arrow, last if up arrow   
        }

        function highlightRow(gethighlight) { //now dual use - either set or get the highlighted row
          gethighlight = gethighlight === true;
          var t = trows.length;
          while (--t > -1) {
            trow = trows[t];
            if(gethighlight && trow.className === 'is-selected'){return t;}
            else if (!gethighlight && trow !== this) { trow.className = 'normal'; }
          }//end while

          return gethighlight? null : this.className = this.className === 'is-selected'? 'normal' : 'is-selected';
        }            
      },      
      toggleSearchDeleted() {
        this.debouncedSearchQuery()          
      },
      exit() {
        this.$store.dispatch('orders/setSelectAOperator', false)
          .then(() => {
            if(this.order && this.order.id) {
              this.$router.push({ name: 'ServiceEdit', params: { id: parseInt(this.order.id) } })
            } else {
              this.$router.push({ name: 'ServiceAdd' })
            }
          })        
      },
      details(operator) {
        if(this.selectAOperator) {
          this.$store.dispatch('orders/setOperator', operator)
            .then(() => {
              if(this.order && this.order.id) {
                this.$router.push({ name: 'ServiceEdit', params: { id: parseInt(this.order.id) } })
              } else {
                this.$router.push({ name: 'ServiceAdd' })
              }
            })  
        } else {
          this.$router.push({ name: 'OperatorsDetails', params: { id: operator.id }})
        }
      }       
    }       
  }
</script>

<style lang="scss" scoped>

</style>